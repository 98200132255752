<template>
  <td
    :class="`element-content cell_type_${cell.type}`"
    :data-clone-index="clone"
    :style="elementStyle"
  >
    <template
      v-if="options.length"
    >
      <template
        v-for="(option, i) in options"
      >
        <v-radio-group
          :key="i"
          v-model="selected[option.value]"
        >
          <v-radio
            :label="option.label"
            :value="option.value"
            :disabled="disabled"
            hide-details
            @change="onChange(option)"
          />
        </v-radio-group>
      </template>
    </template>
    <template
      v-else
    >
      <br>
    </template>
  </td>
</template>

<script>
export default {
  name: 'ChecklistCellTypeMultiselectRadio',
  props: [
    'x',
    'y',
    'cell',
    'checklist',
    'state',
    'clone',
    'dataViewer',
    'data'
  ],
  data() {
    return {
      options: this.cell.settings.multiselect_radio_builder || [],
      selected: this.getSelected(),
      values: this.getValues()
    }
  },
  computed: {
    elementStyle() {
      let style = false
      const color = this.dataViewer.temporary_background_color ||
      this.dataViewer.background_color ||
      this.cell.settings.cell_background_color
      if (color) {
        style = `background-color: ${color};`
      }
      return style
    },
    style() {
      let style = 'width: 100%;'
      const outlineColor = this.dataViewer.outline_color
      if (outlineColor) {
        style += ` outline: 1px solid ${outlineColor};`
      }
      return style
    },
    disabled() {
      return this.state.disabled
    }
  },
  methods: {
    getSelected() {
      const builder = this.data.ChecklistBuilder
      let values = builder.getCellValue(this.checklist.index, this.cell, this.clone, [])
      const selected = {}
      try {
        if (typeof values === 'string') {
          values = JSON.parse(values)
        }
      } catch (ex) {
        values = []
      }
      const options = this.cell.settings.multiselect_radio_builder || []
      for (let option, i = 0; (option = options[i]); i++) {
        if (!selected[option.value]) {
          selected[option.value] = null
        }
      }
      for (let value, i = 0; (value = values[i]); i++) {
        selected[value.id] = value.id
      }
      return selected
    },
    getValues() {
      const builder = this.data.ChecklistBuilder
      let values = builder.getCellValue(this.checklist.index, this.cell, this.clone, [])
      const selected = []
      try {
        if (typeof values === 'string') {
          values = JSON.parse(values)
        }
      } catch (ex) {
        values = []
      }
      for (let value, i = 0; (value = values[i]); i++) {
        selected.push({
          value: value.id,
          label: value.label
        })
      }
      return selected
    },
    onChange(option) {
      const builder = this.data.ChecklistBuilder
      const index = this.checklist.index
      const previousValues = builder.getCellValue(index, this.cell, this.clone, [])
      const max = this.cell.settings.multiselect_radio_max_selected || 0
      this.values.push(option)
      if (max) {
        while (true) {
          if (this.values.length > max) {
            this.selected[this.values[0].value] = null
            this.values.splice(0, 1)
          } else {
            break
          }
        }
      }
      const values = []
      for (let value, i = 0; (value = this.values[i]); i++) {
        values.push({
          id: value.value,
          label: value.label
        })
      }
      builder.evalValues({
        index: index,
        dataEditor: this.cell,
        dataViewer: this.dataViewer,
        cloneIndex: this.clone,
        encode: false,
        saveCallback: undefined,
        ignoreAutoSaveSetting: false,
        value: values,
        previousValue: previousValues
      })
    }
  }
}
</script>

<style>
  .checklist-viewer .checklist-content .cell_type_multiselect_radio .v-input--radio-group {
    margin-top: 0px;
    padding: 2px 0px;
  }
  .checklist-viewer .checklist-content .cell_type_multiselect_radio .v-messages {
    display: none;
  }
</style>
